<template>
  <BaseNavItem :title="$t('title')" :icon="$vuetify.icons.values.deals" :route="targetRoute" :visible="visible" />
</template>

<script>
import { mapState } from 'vuex'
import { BaseNavItem } from '@argon/app/components'
import { NAMESPACE as ORG_NAMESPACE } from '@argon/iam/organizations/store'
import dealPerimeter from '../perimeters'

export default {
  name: 'HomeDealsNavItem',
  components: { BaseNavItem },
  perimeters: [dealPerimeter],
  computed: {
    ...mapState(ORG_NAMESPACE, ['activeOrgRefname']),
    targetRoute() {
      let params = this.activeOrgRefname
        ? { orgRef: this.activeOrgRefname, boardRef: 'home' }
        : { ...this.$route.params, boardRef: 'home' }
      return { name: 'tableView', params }
    },
    visible() {
      return this.$deal.isAllowed('atLeastManager')
    }
  }
}
</script>

<i18n>
{
  "en": {
    "title": "Home"
  },
  "fr": {
    "title": "Habitation"
  }
}
</i18n>
